import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { QtySelectOptionsType } from 'Type/ProductQtyConfig';

import SaasInputSelect from './SaasInputSelectField.component';

/** @namespace Pwasaas/Component/SaasInputSelectField/Container/SaasInputSelectFieldContainer */
export class SaasInputSelectFieldContainer extends PureComponent {
    static propTypes = {
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        max: PropTypes.number,
        onChange: PropTypes.func.isRequired,
        isSelect: PropTypes.bool,
        selectOptions: PropTypes.arrayOf(QtySelectOptionsType)
    };

    static defaultProps = {
        isSelect: false,
        value: null,
        max: null,
        selectOptions: []
    };

    state = {
        isSelectExpanded: false,
        currentValue: 1
    };

    containerFunctions = {
        handleCollapSelect: this.handleCollapSelect.bind(this),
        handleInputStateChange: this.handleInputStateChange.bind(this),
        handleToggleSelect: this.handleToggleSelect.bind(this),
        selectOptionValue: this.selectOptionValue.bind(this),
        inputUpdateQty: this.inputUpdateQty.bind(this)
    };

    componentDidMount() {
        const { value } = this.props;

        if (!value) {
            return;
        }

        this.setCurrentValue(value);
    }

    componentDidUpdate(prevProps) {
        const { value: prevValue } = prevProps;
        const { value } = this.props;

        if (value !== prevValue && value) {
            this.setCurrentValue(value);
        }
    }

    handleCollapSelect() {
        this.setState({ isSelectExpanded: false });
    }

    handleToggleSelect() {
        const { isSelect } = this.props;

        if (!isSelect) {
            return;
        }

        this.setState(({ isSelectExpanded: wasExpanded }) => ({
            isSelectExpanded: !wasExpanded
        }));
    }

    handleInputStateChange(event) {
        const { currentTarget: { value } } = event;

        this.setCurrentValue(value);
    }

    inputUpdateQty() {
        const { onChange, value } = this.props;
        const { currentValue } = this.state;

        if (currentValue < 1 || currentValue === Number(value)) {
            return;
        }

        onChange(currentValue);
    }

    selectOptionValue(event) {
        event.preventDefault();
        event.stopPropagation();
        const { target } = event;

        this.setCurrentValue(target.getAttribute('data-value'), true);
    }

    setCurrentValue(value, emitEvent = false) {
        const { onChange } = this.props;
        const { currentValue: oldValue } = this.state;
        const currentValue = Number(value);

        if (currentValue === oldValue) {
            return;
        }

        this.setState({
            currentValue,
            isSelectExpanded: false
        }, () => {
            if (emitEvent) {
                onChange(currentValue);
            }
        });
    }

    containerProps = () => {
        const {
            onChange,
            max,
            isSelect,
            selectOptions
        } = this.props;

        const {
            isSelectExpanded,
            currentValue
        } = this.state;

        return {
            currentValue,
            onChange,
            isSelectExpanded,
            max,
            isSelect,
            selectOptions
        };
    };

    render() {
        return (
            <SaasInputSelect
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default SaasInputSelectFieldContainer;
